var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "animated fadeIn" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c("b-card", [
          _c("h4", { staticClass: "d-block mb-4" }, [
            _c("i", { staticClass: "nav-icon icon-plus" }),
            _vm._v(" Estimates "),
            _c("i", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value:
                    "<p>1. Select a project</p><p>2. Select a vendor</p><p>3. Create service</p><p>4. Attach AFES</p>",
                  expression:
                    "'<p>1. Select a project</p><p>2. Select a vendor</p><p>3. Create service</p><p>4. Attach AFES</p>'"
                }
              ],
              staticClass: "ml-2 fa fa fa-question-circle"
            })
          ]),
          _c("hr"),
          _c("div", { staticClass: "row my-3" }, [
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("h5", [_vm._v("Project")]),
                _c("v-select", {
                  staticClass: "project-select",
                  attrs: {
                    clearable: false,
                    label: "projectname",
                    options: _vm.projectData,
                    value: _vm.projectid,
                    placeholder: "Search For Project"
                  },
                  on: {
                    input: function($event) {
                      return _vm.loadVendors()
                    }
                  },
                  model: {
                    value: _vm.project,
                    callback: function($$v) {
                      _vm.project = $$v
                    },
                    expression: "project"
                  }
                })
              ],
              1
            ),
            _vm.project
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("h5", [_vm._v("Vendor")]),
                    _c("v-select", {
                      staticClass: "project-select",
                      attrs: {
                        clearable: false,
                        label: "company_name",
                        options: _vm.vendorData
                      },
                      model: {
                        value: _vm.vasid,
                        callback: function($$v) {
                          _vm.vasid = $$v
                        },
                        expression: "vasid"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.project
              ? _c("div", { staticClass: "col-md-4" }, [
                  _c("h5", [_vm._v(" ")]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      on: {
                        click: function($event) {
                          return _vm.loadExisting()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-eye" }),
                      _vm._v(" View Estimates")
                    ]
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm.addNew == 1
          ? _c("b-card", [
              _c("h4", [_vm._v("Add New Estimate")]),
              _c("table", { staticClass: "table table-striped pre-table" }, [
                _c("thead", [
                  _c("tr", { staticClass: "service-rows" }, [
                    _c("th", { staticClass: "center service-name" }, [
                      _vm._v("Service")
                    ]),
                    _c("th", { staticClass: "datepickers right" }, [
                      _vm._v("Start Date")
                    ]),
                    _c("th", { staticClass: "datepickers right" }, [
                      _vm._v("End Date")
                    ]),
                    _c("th", { staticClass: "uom-select" }, [
                      _vm._v("UOM "),
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "fa fa-question-circle",
                        attrs: { title: "Unit Of Measurement" }
                      })
                    ]),
                    _c("th", { staticClass: "price center" }, [
                      _vm._v("Price/Unit")
                    ]),
                    _c("th", { staticClass: "price center" }, [
                      _vm._v("Discounted Price/Unit\n              ")
                    ]),
                    _c("th", { staticClass: "price center" }, [
                      _vm._v("Quantity")
                    ]),
                    _c("th", { staticClass: "price center" }, [
                      _vm._v("Discount (%)")
                    ]),
                    _c("th", { staticClass: "right" }, [_vm._v("Total")]),
                    _c("th", [_vm._v("Add AFE")])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.preServiceData, function(service, i) {
                    return _c(
                      "tr",
                      {
                        staticClass: "service-rows create-service",
                        staticStyle: { background: "rgba(189, 78, 78, 0.22)" }
                      },
                      [
                        _c("td", { staticClass: "center service-name" }, [
                          _c(
                            "h6",
                            {
                              staticClass: "d-xs-block d-xl-none mobile-header"
                            },
                            [_vm._v("Service")]
                          ),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: service.serviceid,
                                  expression: "service.serviceid"
                                }
                              ],
                              staticClass: "w-100 rt-select",
                              attrs: {
                                disabled: _vm.preServiceData[0].afe.length > 0
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      service,
                                      "serviceid",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function($event) {
                                    service.uom = [
                                      service.serviceid.pu1,
                                      service.serviceid.uom1
                                    ]
                                  }
                                ]
                              }
                            },
                            _vm._l(_vm.filterVendorData, function(name) {
                              return _c(
                                "option",
                                { domProps: { value: name } },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(name.name) +
                                      " (" +
                                      _vm._s(name.currency) +
                                      ") " +
                                      _vm._s(_vm.getVendorName(name.vid)) +
                                      "\n                  "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]),
                        _c(
                          "td",
                          { staticClass: "datepickers right" },
                          [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v("Start Date")
                            ]),
                            _c("datepicker", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "rt-date-picker",
                              attrs: {
                                format: "MM/dd/yyyy",
                                name: "service.start_date",
                                placeholder: "Dropdown"
                              },
                              model: {
                                value: service.start_date,
                                callback: function($$v) {
                                  _vm.$set(service, "start_date", $$v)
                                },
                                expression: "service.start_date"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "datepickers right" },
                          [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v("End Date")
                            ]),
                            _c("datepicker", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "rt-date-picker",
                              attrs: {
                                name: "service.end_date",
                                placeholder: "Dropdown",
                                format: "MM/dd/yyyy"
                              },
                              model: {
                                value: service.end_date,
                                callback: function($$v) {
                                  _vm.$set(service, "end_date", $$v)
                                },
                                expression: "service.end_date"
                              }
                            })
                          ],
                          1
                        ),
                        _c("td", { staticClass: "left uom-select" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v("Unit Of Measurement")
                          ]),
                          service.serviceid
                            ? _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    },
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.uom,
                                      expression: "service.uom"
                                    }
                                  ],
                                  staticClass: "w-100 rt-select",
                                  class: {
                                    "is-danger": _vm.errors.has("service.uom")
                                  },
                                  attrs: {
                                    name: "service.uom",
                                    placeholder: "Select"
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          service,
                                          "uom",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function($event) {
                                        return _vm.Pu_toggler(
                                          service,
                                          service.discount,
                                          service.uom[0]
                                        )
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { selected: "" },
                                      domProps: {
                                        value: [
                                          service.serviceid.pu1,
                                          service.serviceid.uom1
                                        ]
                                      }
                                    },
                                    [_vm._v(_vm._s(service.serviceid.uom1))]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value: [
                                          service.serviceid.pu2,
                                          service.serviceid.uom2
                                        ]
                                      }
                                    },
                                    [_vm._v(_vm._s(service.serviceid.uom2))]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      domProps: {
                                        value: [
                                          service.serviceid.pu3,
                                          service.serviceid.uom3
                                        ]
                                      }
                                    },
                                    [_vm._v(_vm._s(service.serviceid.uom3))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c(
                            "h6",
                            {
                              staticClass: "d-xs-block d-xl-none",
                              staticStyle: { "margin-bottom": "13px" }
                            },
                            [_vm._v("Price")]
                          ),
                          service.uom
                            ? _c("span", [
                                _vm._v(
                                  "$" + _vm._s(_vm.formatPrice(service.uom[0]))
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c(
                            "h6",
                            {
                              staticClass: "d-xs-block d-xl-none",
                              staticStyle: { "margin-bottom": "13px" }
                            },
                            [_vm._v("Discounted Price/Unit")]
                          ),
                          service.serviceid.name
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: service.discounted_price_per_unit,
                                    expression:
                                      "service.discounted_price_per_unit"
                                  }
                                ],
                                domProps: {
                                  value: service.discounted_price_per_unit
                                },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        service,
                                        "discounted_price_per_unit",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      return _vm.Discount(
                                        service,
                                        service.discounted_price_per_unit,
                                        service.uom[0]
                                      )
                                    }
                                  ]
                                }
                              })
                            : _vm._e()
                        ]),
                        _c(
                          "td",
                          {
                            staticClass: "price left",
                            staticStyle: { "padding-bottom": "0.5rem" }
                          },
                          [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v("Quantity")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: service.quantity,
                                  expression: "service.quantity"
                                }
                              ],
                              staticClass: "rt-round",
                              attrs: { type: "text" },
                              domProps: { value: service.quantity },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    service,
                                    "quantity",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "price left",
                            staticStyle: { "padding-bottom": "0.5rem" }
                          },
                          [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v("Discount (%)")
                            ]),
                            service.serviceid.name
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: service.discount,
                                      expression: "service.discount"
                                    }
                                  ],
                                  staticClass: "rt-round",
                                  attrs: { type: "text" },
                                  domProps: { value: service.discount },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service,
                                          "discount",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        return _vm.discounted(
                                          service,
                                          service.discount,
                                          service.uom[0]
                                        )
                                      }
                                    ]
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        _c("td", { staticClass: "right v-align-middle" }, [
                          _c(
                            "h6",
                            {
                              staticClass: "d-xs-block d-xl-none",
                              staticStyle: { "margin-bottom": "13px" }
                            },
                            [_vm._v("Total")]
                          ),
                          service.uom
                            ? _c("span", [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        service.quantity * service.uom[0] -
                                          (service.discount / 100) *
                                            service.quantity *
                                            service.uom[0]
                                      )
                                    )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("td", { staticClass: "add-afe-button" }, [
                          _vm
                            .$moment(service.start_date)
                            .format("YYYY-MM-DD") <=
                            _vm
                              .$moment(service.end_date)
                              .format("YYYY-MM-DD") &&
                          service.start_date != "" &&
                          service.end_date != "" &&
                          service.quantity >= 0 &&
                          service.uom &&
                          service.discount >= 0 &&
                          service.discount <= 100
                            ? _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass:
                                    "view btn rt-d-inline btn-sm btn-success",
                                  staticStyle: {
                                    width: "58px",
                                    "max-width": "58px"
                                  },
                                  attrs: {
                                    disabled:
                                      _vm.beforeEditAfeCacheCatch == 1 ||
                                      _vm.beforeEditCacheCatch == 1,
                                    title: "Add AFE",
                                    type: "button"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addNewPreAfe(
                                        service.serviceid.serviceid
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v("AFE "),
                                  _c("i", { staticClass: "fa fa-plus-circle" })
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]),
              _vm.project && _vm.preServiceData.length > 0
                ? _c("div", [
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "service-rows" }, [
                          _c("th", { staticClass: "actions fit" }),
                          _c("th", { staticClass: "center" }, [
                            _vm._v("AFE #")
                          ]),
                          _c("th", { staticClass: "center fit" }, [
                            _vm._v("% "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "fa fa-question-circle",
                              attrs: { title: "AFE Percentage" }
                            })
                          ]),
                          _c("th", { staticClass: "center" }, [
                            _vm._v("Cost Code #1")
                          ]),
                          _c("th", { staticClass: "center" }, [
                            _vm._v("Cost Code #2")
                          ]),
                          _c("th", { staticClass: "center" }, [
                            _vm._v("Cost Code #3")
                          ]),
                          _c("th", { staticClass: "center fit" })
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.preServiceData[0].afe, function(afe, index) {
                          return _c("tr", [
                            _c("td", { staticClass: "actions fit" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-danger mt-1",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancelAfe(index)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-times-circle" })]
                              )
                            ]),
                            _c(
                              "td",
                              { staticClass: "left" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("AFE #")]
                                ),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    label: "afenum",
                                    options: afe.chain,
                                    value: _vm.afenum,
                                    searchable: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.setcc1(afe.afe.items, index)
                                    }
                                  },
                                  model: {
                                    value: afe.afe,
                                    callback: function($$v) {
                                      _vm.$set(afe, "afe", $$v)
                                    },
                                    expression: "afe.afe"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("td", { staticClass: "left fit" }, [
                              _c(
                                "h6",
                                { staticClass: "d-xs-block d-xl-none" },
                                [_vm._v("%")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: afe.percent,
                                    expression: "afe.percent"
                                  }
                                ],
                                staticClass: "rt-quantity",
                                attrs: {
                                  type: "number",
                                  step: "any",
                                  min: "0",
                                  max: "100"
                                },
                                domProps: { value: afe.percent },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      afe,
                                      "percent",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _c(
                              "td",
                              { staticClass: "left" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Cost Code #1")]
                                ),
                                _c("v-select", {
                                  staticClass: "v-select-sm",
                                  attrs: {
                                    clearable: false,
                                    label: "ccone_code",
                                    options: afe.cc1Items,
                                    value: _vm.ccone_code,
                                    searchable: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.setcc2(afe.cc1.items, index)
                                    }
                                  },
                                  model: {
                                    value: afe.cc1,
                                    callback: function($$v) {
                                      _vm.$set(afe, "cc1", $$v)
                                    },
                                    expression: "afe.cc1"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "left" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Cost Code #2")]
                                ),
                                _c("v-select", {
                                  staticClass: "v-select-sm",
                                  attrs: {
                                    clearable: false,
                                    label: "cctwo_code",
                                    options: afe.cc2Items,
                                    value: _vm.cctwo_code,
                                    searchable: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.setcc3(afe.cc2.items, index)
                                    }
                                  },
                                  model: {
                                    value: afe.cc2,
                                    callback: function($$v) {
                                      _vm.$set(afe, "cc2", $$v)
                                    },
                                    expression: "afe.cc2"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "left" },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Cost Code #3")]
                                ),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    label: "ccthree_code",
                                    options: afe.cc3Items,
                                    value: _vm.ccthree_code,
                                    searchable: false
                                  },
                                  model: {
                                    value: afe.cc3,
                                    callback: function($$v) {
                                      _vm.$set(afe, "cc3", $$v)
                                    },
                                    expression: "afe.cc3"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.project && _vm.preServiceData[0]
                ? _c("div", [
                    _vm.project &&
                    _vm
                      .$moment(_vm.preServiceData[0].start_date)
                      .format("YYYY-MM-DD") <=
                      _vm
                        .$moment(_vm.preServiceData[0].end_date)
                        .format("YYYY-MM-DD") &&
                    _vm.preServiceData[0].start_date != "" &&
                    _vm.preServiceData[0].end_date != "" &&
                    _vm.preServiceData[0].quantity >= 0 &&
                    _vm.preServiceData[0].uom &&
                    _vm.preServiceData[0].discount >= 0 &&
                    _vm.preServiceData[0].discount <= 100
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "btn btn-sm btn-blue",
                            attrs: {
                              title: "Save Service",
                              disabled:
                                _vm.beforeEditAfeCacheCatch == 1 ||
                                _vm.beforeEditCacheCatch == 1
                            },
                            on: {
                              click: function($event) {
                                return _vm.addPreService(
                                  _vm.preServiceData[0],
                                  0
                                )
                              }
                            }
                          },
                          [
                            _vm._v("Save Service To Estimates "),
                            _c("i", { staticClass: "fa fa-save" })
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm.project && _vm.sortedServiceData.length > 0
          ? _c(
              "div",
              _vm._l(_vm.sortedServiceData, function(service, index) {
                return _c(
                  "b-card",
                  { key: "wsid" },
                  [
                    _c("h5", [
                      _vm._v(
                        _vm._s(service.service[0].serviceid.name) +
                          " (" +
                          _vm._s(
                            _vm.getVendorName(service.service[0].serviceid.vid)
                          ) +
                          ")"
                      )
                    ]),
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "service-rows" }, [
                          _c("th", { staticClass: "fit" }),
                          _c("th", { staticClass: "datepickers right" }, [
                            _vm._v("Start Date")
                          ]),
                          _c("th", { staticClass: "datepickers right" }, [
                            _vm._v("End Date")
                          ]),
                          _c("th", { staticClass: "uom-select" }, [
                            _vm._v("UOM "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "fa fa-question-circle",
                              attrs: { title: "Unit Of Measurement" }
                            })
                          ]),
                          _c("th", { staticClass: "price center" }, [
                            _vm._v("Price/Unit")
                          ]),
                          _c("th", { staticClass: "price center" }, [
                            _vm._v(" Discounted Price/Unit")
                          ]),
                          _c("th", { staticClass: "price center" }, [
                            _vm._v("Quantity")
                          ]),
                          _c("th", { staticClass: "price center" }, [
                            _vm._v("Discount (%)")
                          ]),
                          _c("th", { staticClass: "right" }, [_vm._v("Total")]),
                          _c("th")
                        ])
                      ]),
                      _c("tbody", [
                        _c(
                          "tr",
                          {
                            staticClass: "service-rows",
                            class: { editing: service == _vm.editedLine }
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "d-xs-none d-lg-block fit border-0"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-sm btn-danger",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        _vm.beforeEditCacheCatch == 1 ||
                                        _vm.beforeEditAfeCacheCatch == 1 ||
                                        service.service.length > 1
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeService(
                                          index,
                                          service.service[0].wsid
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-minus" })]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "datepickers d-none d-lg-table-cell center price v-align-middle"
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Start Date")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "view",
                                    on: {
                                      click: function($event) {
                                        return _vm.editData(service, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            service.service[0].start_date,
                                            "MM/DD/YYYY"
                                          )
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "edit" },
                                  [
                                    _c("datepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'"
                                        }
                                      ],
                                      staticClass: "rt-date-picker",
                                      attrs: {
                                        format: "MM/dd/yyyy",
                                        name: "service.service[0].start_date",
                                        placeholder: "Dropdown"
                                      },
                                      model: {
                                        value: service.service[0].start_date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            service.service[0],
                                            "start_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "service.service[0].start_date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "datepickers d-none d-lg-table-cell center price v-align-middle"
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("End Date")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "view",
                                    on: {
                                      click: function($event) {
                                        return _vm.editData(service, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            service.service[0].end_date,
                                            "MM/DD/YYYY"
                                          )
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "edit" },
                                  [
                                    _c("datepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'"
                                        }
                                      ],
                                      staticClass: "rt-date-picker",
                                      class: {
                                        "date-danger":
                                          _vm
                                            .$moment(
                                              service.service[0].start_date
                                            )
                                            .format("YYYY-MM-DD") <
                                          _vm
                                            .$moment(
                                              service.service[0].end_date
                                            )
                                            .format("YYYY-MM-DD")
                                      },
                                      attrs: {
                                        format: "MM/dd/yyyy",
                                        name: "service.service[0].end_date",
                                        placeholder: "Dropdown"
                                      },
                                      model: {
                                        value: service.service[0].end_date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            service.service[0],
                                            "end_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "service.service[0].end_date"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "d-none d-lg-table-cell left price v-align-middle"
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Unit Of Measurement")]
                                ),
                                service.service[0].uom
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "view",
                                        on: {
                                          click: function($event) {
                                            return _vm.editData(service, index)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(service.service[0].uom[1]) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "edit" }, [
                                  service.service[0].serviceid
                                    ? _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'"
                                            },
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: service.service[0].uom,
                                              expression:
                                                "service.service[0].uom"
                                            }
                                          ],
                                          staticClass: "w-100 rt-select",
                                          class: {
                                            "is-danger": _vm.errors.has(
                                              "service.service[0].uom"
                                            )
                                          },
                                          attrs: {
                                            name: "service.service[0].uom",
                                            placeholder: "Select"
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  service.service[0],
                                                  "uom",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function($event) {
                                                return _vm.Pu_toggler(
                                                  service.service[0],
                                                  service.service[0].discount,
                                                  service.service[0].uom[0]
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: [
                                                  service.service[0].serviceid
                                                    .pu1,
                                                  service.service[0].serviceid
                                                    .uom1
                                                ]
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  service.service[0].serviceid
                                                    .uom1
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: [
                                                  service.service[0].serviceid
                                                    .pu2,
                                                  service.service[0].serviceid
                                                    .uom2
                                                ]
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  service.service[0].serviceid
                                                    .uom2
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: [
                                                  service.service[0].serviceid
                                                    .pu3,
                                                  service.service[0].serviceid
                                                    .uom3
                                                ]
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  service.service[0].serviceid
                                                    .uom3
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "d-none d-lg-table-cell price left v-align-middle"
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Price")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "view",
                                    on: {
                                      click: function($event) {
                                        return _vm.editData(service, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    $" +
                                        _vm._s(
                                          _vm.formatPrice(service.service[0].pu)
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "edit" }, [
                                  service.service[0].uom
                                    ? _c("span", [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              _vm.formatPrice(
                                                service.service[0].uom[0]
                                              )
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "d-none d-lg-table-cell price left v-align-middle"
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v(" Discounted Price/Unit")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "view",
                                    on: {
                                      click: function($event) {
                                        return _vm.editData(service, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n\n        " +
                                        _vm._s(
                                          service.service[0]
                                            .discounted_price_per_unit
                                            ? _vm.formatPrice(
                                                service.service[0]
                                                  .discounted_price_per_unit
                                              )
                                            : _vm._discount_price_per_unit(
                                                service.service[0].discount,
                                                service.service[0].uom[0]
                                              )
                                        ) +
                                        "\n\n      "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "edit" }, [
                                  service.service[0].serviceid.name &&
                                  !service.service[0].discounted_price_per_unit
                                    ? _c("input", {
                                        domProps: {
                                          value: _vm._discount_price_per_unit(
                                            service.service[0].discount,
                                            service.service[0].uom[0]
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  service.service[0].serviceid.name &&
                                  service.service[0].discounted_price_per_unit
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              service.service[0]
                                                .discounted_price_per_unit,
                                            expression:
                                              "service.service[0].discounted_price_per_unit"
                                          }
                                        ],
                                        domProps: {
                                          value:
                                            service.service[0]
                                              .discounted_price_per_unit
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                service.service[0],
                                                "discounted_price_per_unit",
                                                $event.target.value
                                              )
                                            },
                                            function($event) {
                                              return _vm.Discount(
                                                service.service[0],
                                                service.service[0]
                                                  .discounted_price_per_unit,
                                                service.service[0].uom[0]
                                              )
                                            }
                                          ]
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "d-none d-lg-table-cell price left v-align-middle"
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Quantity")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "view",
                                    on: {
                                      click: function($event) {
                                        return _vm.editData(service, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(service.service[0].quantity) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "edit" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: service.service[0].quantity,
                                        expression:
                                          "service.service[0].quantity"
                                      }
                                    ],
                                    staticClass: "rt-round",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: service.service[0].quantity
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          service.service[0],
                                          "quantity",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "d-none d-lg-table-cell price left v-align-middle"
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Discount")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "view",
                                    on: {
                                      click: function($event) {
                                        return _vm.editData(service, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    $" +
                                        _vm._s(
                                          _vm.formatPrice(
                                            (service.service[0].discount /
                                              100) *
                                              service.service[0].pu *
                                              service.service[0].quantity
                                          )
                                        ) +
                                        " (" +
                                        _vm._s(service.service[0].discount) +
                                        "%)\n                  "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "edit" }, [
                                  service.service[0].serviceid.name
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: service.service[0].discount,
                                            expression:
                                              "service.service[0].discount"
                                          }
                                        ],
                                        staticClass: "rt-round",
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: service.service[0].discount
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                service.service[0],
                                                "discount",
                                                $event.target.value
                                              )
                                            },
                                            function($event) {
                                              return _vm.discounted(
                                                service.service[0],
                                                service.service[0].discount,
                                                service.service[0].uom[0]
                                              )
                                            }
                                          ]
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "d-none d-lg-table-cell right v-align-middle"
                              },
                              [
                                _c(
                                  "h6",
                                  { staticClass: "d-xs-block d-xl-none" },
                                  [_vm._v("Total")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "view",
                                    on: {
                                      click: function($event) {
                                        return _vm.editData(service, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    $" +
                                        _vm._s(service.service[0].total) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "edit" }, [
                                  service.service[0].uom
                                    ? _c("span", [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              _vm.formatPrice(
                                                service.service[0].quantity *
                                                  service.service[0].uom[0] -
                                                  (service.service[0].discount /
                                                    100) *
                                                    service.service[0]
                                                      .quantity *
                                                    service.service[0].uom[0]
                                              )
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "d-none d-lg-table-cell right" },
                              [
                                _vm
                                  .$moment(service.service[0].start_date)
                                  .format("YYYY-MM-DD") <=
                                  _vm
                                    .$moment(service.service[0].end_date)
                                    .format("YYYY-MM-DD") &&
                                service.service[0].quantity >= 0 &&
                                service.service[0].discount >= 0 &&
                                service.service[0].discount <= 100
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "edit rt-d-inline mr-2 btn btn-sm btn-primary",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.editService(
                                              service.service[0]
                                            )
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-edit" })]
                                    )
                                  : _vm._e(),
                                _vm.beforeEditAfeCacheCatch != 1 &&
                                _vm.beforeEditCacheCatch != 1
                                  ? _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle",
                                            value: "c-" + index,
                                            expression: "'c-' + index"
                                          }
                                        ],
                                        staticClass: "btn-sm",
                                        staticStyle: {
                                          width: "100%",
                                          "max-width": "62px"
                                        },
                                        attrs: { variant: "primary" }
                                      },
                                      [
                                        _vm._v("AFE'S "),
                                        _c("i", {
                                          staticClass: "fa fa-chevron-down"
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]),
                    _c(
                      "b-collapse",
                      {
                        staticClass: "mt-2",
                        attrs: { accordion: "afe-collapse", id: "c-" + index }
                      },
                      [
                        _c("table", { staticClass: "table table-striped" }, [
                          _c("thead", [
                            _c("tr", { staticClass: "service-rows" }, [
                              _c("th", { staticClass: "actions fit" }),
                              _c("th", { staticClass: "center" }, [
                                _vm._v("AFE #")
                              ]),
                              _c("th", { staticClass: "center fit" }, [
                                _vm._v("% "),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass: "fa fa-question-circle",
                                  attrs: { title: "AFE Percentage" }
                                })
                              ]),
                              _c("th", { staticClass: "center" }, [
                                _vm._v("Cost Code #1")
                              ]),
                              _c("th", { staticClass: "center" }, [
                                _vm._v("Cost Code #2")
                              ]),
                              _c("th", { staticClass: "center" }, [
                                _vm._v("Cost Code #3")
                              ]),
                              _c("th", { staticClass: "center fit" })
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(service.service, function(afe, aIndex) {
                              return afe.afenum
                                ? _c(
                                    "tr",
                                    {
                                      staticClass: "v-align afe-table",
                                      class: {
                                        editingAfe: afe == _vm.editedAfeLine
                                      }
                                    },
                                    [
                                      _c(
                                        "td",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          staticClass: "actions",
                                          attrs: {
                                            title: "Remove AFE From Invoice"
                                          }
                                        },
                                        [
                                          !afe.add
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "fit btn btn-sm btn-danger",
                                                  attrs: {
                                                    disabled:
                                                      _vm.beforeEditAfeCacheCatch ==
                                                        1 ||
                                                      _vm.beforeEditCacheCatch ==
                                                        1,
                                                    type: "button"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeAfe(
                                                        afe.apwid
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-minus-circle"
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          afe.add == 1
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-sm btn-danger",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.cancelAfe()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-times-circle"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _c("td", { staticClass: "left" }, [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("AFE #")]
                                        ),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeData(
                                                      afe,
                                                      index,
                                                      aIndex
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(afe.afenum) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              { staticClass: "edit" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    label: "afenum",
                                                    options:
                                                      _vm.serviceChainData,
                                                    value: _vm.afenum
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.getcc1()
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.edit_afe,
                                                    callback: function($$v) {
                                                      _vm.edit_afe = $$v
                                                    },
                                                    expression: "edit_afe"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        afe.add == 1
                                          ? _c(
                                              "div",
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    label: "afenum",
                                                    options:
                                                      _vm.serviceChainData,
                                                    value: _vm.afenum,
                                                    searchable: false
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.addcc1()
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.add_afe,
                                                    callback: function($$v) {
                                                      _vm.add_afe = $$v
                                                    },
                                                    expression: "add_afe"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c("td", { staticClass: "left fit" }, [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("%")]
                                        ),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeData(
                                                      afe,
                                                      index,
                                                      aIndex
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(afe.percentage) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !afe.add
                                          ? _c("div", { staticClass: "edit" }, [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.edit_percentage,
                                                    expression:
                                                      "edit_percentage"
                                                  }
                                                ],
                                                staticClass: "rt-quantity",
                                                attrs: {
                                                  type: "number",
                                                  step: "any",
                                                  min: "0",
                                                  max: "100"
                                                },
                                                domProps: {
                                                  value: _vm.edit_percentage
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.edit_percentage =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ])
                                          : _vm._e(),
                                        afe.add == 1
                                          ? _c("div", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.add_percentage,
                                                    expression: "add_percentage"
                                                  }
                                                ],
                                                staticClass: "rt-quantity",
                                                attrs: {
                                                  type: "number",
                                                  step: "any",
                                                  min: "0",
                                                  max: "100"
                                                },
                                                domProps: {
                                                  value: _vm.add_percentage
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.add_percentage =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ])
                                          : _vm._e()
                                      ]),
                                      _c("td", { staticClass: "left" }, [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Cost Code #1")]
                                        ),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeData(
                                                      afe,
                                                      index,
                                                      aIndex
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(afe.ccone_code) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              { staticClass: "edit" },
                                              [
                                                _c("v-select", {
                                                  staticClass: "v-select-sm",
                                                  attrs: {
                                                    clearable: false,
                                                    label: "ccone_code",
                                                    options: _vm.cc1Data,
                                                    value: _vm.ccone_code
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.getcc2()
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.edit_ccone_code,
                                                    callback: function($$v) {
                                                      _vm.edit_ccone_code = $$v
                                                    },
                                                    expression:
                                                      "edit_ccone_code"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        afe.add == 1
                                          ? _c(
                                              "div",
                                              [
                                                _c("v-select", {
                                                  staticClass: "v-select-sm",
                                                  attrs: {
                                                    clearable: false,
                                                    label: "ccone_code",
                                                    options: _vm.cc1Data,
                                                    value: _vm.ccone_code,
                                                    searchable: false
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.addcc2()
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.add_ccone_code,
                                                    callback: function($$v) {
                                                      _vm.add_ccone_code = $$v
                                                    },
                                                    expression: "add_ccone_code"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c("td", { staticClass: "left" }, [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Cost Code #2")]
                                        ),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeData(
                                                      afe,
                                                      index,
                                                      aIndex
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(afe.cctwo_code) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              { staticClass: "edit" },
                                              [
                                                _c("v-select", {
                                                  staticClass: "v-select-sm",
                                                  attrs: {
                                                    clearable: false,
                                                    label: "cctwo_code",
                                                    options: _vm.cc2Data,
                                                    value: _vm.cctwo_code
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.getcc3()
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.edit_cctwo_code,
                                                    callback: function($$v) {
                                                      _vm.edit_cctwo_code = $$v
                                                    },
                                                    expression:
                                                      "edit_cctwo_code"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        afe.add == 1
                                          ? _c(
                                              "div",
                                              [
                                                _c("v-select", {
                                                  staticClass: "v-select-sm",
                                                  attrs: {
                                                    clearable: false,
                                                    label: "cctwo_code",
                                                    options: _vm.cc2Data,
                                                    value: _vm.cctwo_code,
                                                    searchable: false
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.addcc3()
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.add_cctwo_code,
                                                    callback: function($$v) {
                                                      _vm.add_cctwo_code = $$v
                                                    },
                                                    expression: "add_cctwo_code"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c("td", { staticClass: "left" }, [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Cost Code #3")]
                                        ),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "view",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editAfeData(
                                                      afe,
                                                      index,
                                                      aIndex
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                afe.ccthree_code
                                                  ? _c("div", [
                                                      !afe.ccthree_code.includes(
                                                        "---"
                                                      )
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  afe.ccthree_code
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ])
                                                        : _vm._e()
                                                    ])
                                                  : _vm._e()
                                              ]
                                            )
                                          : _vm._e(),
                                        !afe.add
                                          ? _c(
                                              "div",
                                              { staticClass: "edit" },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    label: "ccthree_code",
                                                    options: _vm.cc3Data,
                                                    value: _vm.ccthree_code
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.edit_ccthree_code,
                                                    callback: function($$v) {
                                                      _vm.edit_ccthree_code = $$v
                                                    },
                                                    expression:
                                                      "edit_ccthree_code"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        afe.add == 1
                                          ? _c(
                                              "div",
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    label: "ccthree_code",
                                                    options: _vm.cc3Data,
                                                    value: _vm.ccthree_code,
                                                    searchable: false
                                                  },
                                                  model: {
                                                    value: _vm.add_ccthree_code,
                                                    callback: function($$v) {
                                                      _vm.add_ccthree_code = $$v
                                                    },
                                                    expression:
                                                      "add_ccthree_code"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "actions add-afe-button",
                                          staticStyle: { width: "5%" }
                                        },
                                        [
                                          !afe.add
                                            ? _c(
                                                "div",
                                                { staticClass: "edit" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-sm btn-primary",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editAddAfeToInvoice(
                                                            afe.apwid,
                                                            afe.wsid,
                                                            afe
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-edit"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          afe.add == 1
                                            ? _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        modifiers: {
                                                          hover: true
                                                        }
                                                      }
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-success",
                                                    attrs: {
                                                      type: "button",
                                                      title: "Add AFE"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addAfeToInvoice(
                                                          afe.service_ccid,
                                                          afe.wsid,
                                                          afe.id,
                                                          afe
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-plus-circle"
                                                    })
                                                  ]
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          )
                        ]),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass:
                              "view btn rt-d-inline btn-sm btn-success",
                            attrs: {
                              title: "Add AFE",
                              disabled:
                                _vm.beforeEditAfeCacheCatch == 1 ||
                                _vm.beforeEditCacheCatch == 1,
                              type: "button"
                            },
                            on: {
                              click: function($event) {
                                return _vm.addNewAfe(
                                  service.wsid,
                                  service.service[0].serviceid.serviceid,
                                  index,
                                  service
                                )
                              }
                            }
                          },
                          [
                            _vm._v("Add AFE "),
                            _c("i", { staticClass: "fa fa-plus-circle" })
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }